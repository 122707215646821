import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Numbers from '../components/number-tabs'

const DominaPage = ({ data }) => (
  <Layout>
    <SEO title="Telefonsex Domina - Telefonerziehung durch Telefonherrin" description="Unsere Telefonsex Dominas suchen neue Telefonsklaven. Willst du von einer Telefonsex Domina am Telefon erzogen werden? Unsere Fernerziehung macht es möglich." keywords={[`telefonsex`, `telefonerotik`, `telesex`, `telsex`, `domina`]} />
    <section className="hero is-primary">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column has-text-centered is-5 is-offset-1">
              <h1 className="title is-3 has-text-weight-bold">
                TELEFONSEX DOMINA - TELEFONERZIEHUNG DURCH TELEFONHERRIN
              </h1>
              <Numbers kennwort="DOMINA" />
              <div style={{ marginTop: '20px' }}>
                *1,99 Euro/Min.
              </div>
            </div>
            <div className="column has-text-centered is-5">
              <Img position="absolute" fixed={data.imageOne.childImageSharp.fixed} alt="Telefonsex Domina - Telefonerziehung durch Telefonherrin" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content">
              <p>Als Telefonsklave einer Telefonsex Domina erwarten dich harte, aber auch geile Erfahrungen. Die Telefonerziehung durch eine strenge Telefonherrin ist nichts für zarte Gemüter. Unsere Telefonsex Dominas werden dir nämlich konkrete Anweisungen geben. Die hast du dann über die Domina Hotline auszuführen, während deine private Telefondomina dich überwacht. Natürlich dient dein Leid als Telefonsklave der Lust deiner Telefonsex Herrin. Bist du ein braver Sklave, belohnt sie dich vielleicht mit einer Domina Wichsanleitung. Aber zuerst musst du ihre Wichskontrolle ertragen. Dabei wird sie dich durch Wichserziehung zu einem braven Wichssklaven machen. Klingt das geil? Dann genieße jetzt Domina Telefonsex in Premium Qualität.</p>
              <h2 className="title">Domina Telefonsex mit Telefondomina - eine strenge Telefonherrin erwartet dich</h2>
              <p>Du möchtest gern einer strengen Herrin als Sklave dienen, aber das möglicht diskret und am liebsten auch anonym? Nichts leichter als das. Unser Domina Telefonsex mit strenger Telefonherrin macht es möglich. Im Gegensatz zum Dominastudio musst du mit einer Telefonsex Domina keinen Termin ausmachen, sondern kannst einfach anrufen. Über die Domina Hotline erreichst du nämlich rund um die Uhr strenge und dominante Ladys, die dich liebend gern als Telefonsex Mistress zu ihrem Sklaven erziehen. Dabei ist eine solche Telefonerziehung definitiv nichts für Weicheier und auch nicht weniger hart als in einem Studio. Schließlich musst du einer Telefonsex Herrin genauso gehorchen - und dich zudem am Telefon sogar selbst quälen und foltern.</p>
              <Img fluid={data.imageTwo.childImageSharp.fluid} alt="Telefonerziehung über die Domina Hotline - schnell, hart und brutal" style={{ marginBottom: '20px' }} />
              <h3 className="title">Telefonerziehung über die Domina Hotline - schnell, hart und brutal</h3>
              <p>Der größte Vorteil einer Fernerziehung durch eine Domina übers Telefon besteht darin, dass du sofort dein Bedürfnis befriedigen kannst. Wir sind schließlich alle ungeduldig. Aber sei gewarnt: BDSM übers Telefon kann extrem hart und brutal sein. Eine Domina am Sextelefon erwartet genau denselben Gehorsam von dir wie im Studio. Außerdem wird deine Herrin am Telefon dich genauso hart rannehmen. Nur dass du bei einer Telefonerziehung über die Domina Telefonnummer eben alles selbst an dir ausführen musst. Zum Beispiel Penis- und Hodenfolter oder Nippelfolter. Vielleicht will deine Tele Domina auch, dass du dir etwas in den Harnkanal einführst. Bist du bereit dafür? Dann wähle die Domina Nummer. Ansonsten überleg dir das mit der Domina am Telefon besser noch mal.</p>
              <Img fluid={data.imageThree.childImageSharp.fluid} alt="Private Telefondomina in Premium Qualität - Fernerziehung allererster Güte" style={{ marginBottom: '20px' }} />
              <h3 className="title">Private Telefondomina in Premium Qualität - Fernerziehung allererster Güte</h3>
              <p>Unsere Domina Hotline hat hohe Ansprüche an sich selbst. Bei uns bekommst du nämlich eine private Telefondomina in Premium Qualität. So eine Premium Domina am Telefon weiß ganz genau, wie sie mit einem Sklaven umzugehen hat. Dich erwartet also Fernerziehung allererster Güte mit deiner Telefondomina privat übers Domina Sextelefon. Am Ende bist du ein erschöpfter, aber glücklicher Telefonsklave. Weil deine Premium Telefondomina nämlich genau die richtigen Knöpfe in deinem Kopf gedrückt hat. Eine Telefon Erziehung ist schließlich immer Kopfsache. Deshalb muss eine Herrin beim Dominatelefonsex sehr geschickt darin sein, deine psychologischen Trigger zu finden. Das ist es, was eine Telefondomina in Premium Qualität ausmacht.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section has-background-light">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half is-narrow has-text-centered">
            <h2 className="title is-3 has-text-weight-bold">
              TELEFONSEX DOMINA HOTLINE FÜR FERNERZIEHUNG
            </h2>
            <Numbers kennwort="DOMINA" />
            <div style={{ marginTop: '20px' }}>
              *1,99 Euro/Min.
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content">
              <h2 className="title">Telefonsex Dominas über die Domina Hotline für harte Telefonerziehung</h2>
              <p>Es gibt viele Dinge, die eine Telefon Sex Domina via Hotline mit dir anstellen wird. Perverse Rollenspiele verschiedener Art zum Beispiel. So wird deine Telefonsex Herrin dich garantiert mit einem Strapon zum Analsklaven machen. Sehr wahrscheinlich wird die Telefonsex Mistress dich auch auspeitschen oder dein bestes Stück mit der Gerte quälen. Penis- und Hodenfolter ist generell sehr beliebt bei unseren Telefonsex Dominas. Schließlich ist dein bestes Stück sehr empfindlich. Falls du eine besonders strenge Telefonherrin hast, musst du eventuell auch als Toilettensklave dienen. Die Telefonsex Domina hockt sich dann über dein Gesicht und pisst dir ins Maul. (Oder stehst du sogar auf Kaviar?)</p>
              <Img fluid={data.imageFour.childImageSharp.fluid} alt="Wichsanleitung durch Domina am Telefon - Wichskontrolle und Wichserziehung durch Wichs Domina" style={{ marginBottom: '20px' }} />
              <h3 className="title">Wichsanleitung durch Domina am Telefon - Wichskontrolle und Wichserziehung durch Wichs Domina</h3>
              <p>Auch wenn du eine Domina Nummer anrufst, willst du wichsen und abspritzen. Dann ist eine Wichsanleitung durch eine Domina am Telefon besonders geil. So eine Domina Wixanleitung ist natürlich keine normale Wichsanweisung. Gibt eine Domina eine Wichsanleitung, dann ist das eine SM Wichsanleitung und auch ziemlich hart. Sie übernimmt dabei die Wichskontrolle und macht dich durch eine Wichserziehung zum Wichssklaven. Wie genau eine Domina Wichsanleitung abläuft, ist von Wichs Domina zu Wichs Domina verschieden. Alle haben da ihre unterschiedlichen Vorlieben. In jedem Fall aber genießen sie die Orgasmuskontrolle über dich - und werden die bei ihrer Femdom Wichsanleitung vollumfänglich auskosten.</p>
              <Img fluid={data.imageFive.childImageSharp.fluid} alt="Jetzt als Telefonsklave einer Telefonsex Jungdomina zum Üben dienen" style={{ marginBottom: '20px' }} />
              <h3 className="title">Jetzt als Telefonsklave einer Telefonsex Jungdomina zum Üben dienen</h3>
              <p>Du erreichst über unsere Domina Telefonnummer übrigens nicht bloß erfahrene Telefonsex Dominas. Wir haben nämlich auch junge Girls, die als Telefonsex Jungdomina einen Telefonsklaven zum Üben suchen. Manche dieser Mädels sind gerade mal 18 Jahre alt und damit noch echte Teens. Möchtest du gern als gestandener Mann einer Jungdomina für Telefonsex dienen? Das Schöne an einer Fernerziehung übers Telefon ist nämlich, dass sie so unkompliziert ist. Dadurch kann eine Telefonsex Jungdomina sich richtig an dir austoben. Nur mal schnell die Domina Hotline anrufen und schon bist du der Telefonsklave eines jungen Mädels, das an dir seine sadistischen und dominanten Seiten ausprobieren kann.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section has-background-light">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half is-narrow has-text-centered">
            <h2 className="title is-3 has-text-weight-bold">
              TELEFONSEX DOMINAS ERWARTEN DICH
            </h2>
            <Numbers kennwort="DOMINA" />
            <div style={{ marginTop: '20px' }}>
              *1,99 Euro/Min.
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default DominaPage

export const query = graphql`
  query DominaQuery {
    imageOne: file(relativePath: { eq: "telefonsex-domina-1.png" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    imageTwo: file(relativePath: { eq: "telefonsex-domina-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "telefonsex-domina-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour: file(relativePath: { eq: "telefonsex-domina-4.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFive: file(relativePath: { eq: "telefonsex-domina-5.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
